
<template>
    <div>
        <v-card>
            <v-card-title>

            </v-card-title>
            <v-card-text>
                <s-crud title="Registro de permiso de parqueo de vehiculos"                 
                    edit
                    add 
                    load 
                    sortable 
                    remove 
                    :filter="config.filter"
                    :config="config"
                    @rowSelected="rowSelected($event)"
                    @save="Save($event)"
                    number-page="5"
                    @addEvent="addRecom()"  
                   >
                    <template v-slot:default="{ item }">
                        <v-row>
                            <v-col >
                                <v-row>
                                    <v-col cols="3" class="s-col-form">
                                        <s-toolbar-person noCamera noEdit v-model="item.PrsDocumentNumber" @returnPerson="returnPerson($event, item)" />
                                    </v-col>
                                    <v-col cols="3" class="s-col-form">
                                        <s-text
                                            label="Nombres"
                                            v-model="PgdName"
                                            ref="focusPgdName"
                                        ></s-text>
                                    </v-col>
                                    <v-col cols="3" class="s-col-form">
                                        <s-text
                                            label="Apellidos"
                                            v-model="PgdLastName"
                                        ></s-text>
                                    </v-col>              
                                </v-row>
                                <v-row>
                                    <v-col cols="3" class="s-col-form">
                                        <s-date label="Fecha Inicio"
                                            v-model="item.DateBegin" valueZone="America/Lima"                        
                                            >
                                        </s-date>
                                    </v-col>
                                    <v-col cols="3" class="s-col-form">
                                        <s-date label="Fecha Fin"
                                            v-model="item.DateEnd" valueZone="America/Lima"                       
                                            >
                                        </s-date>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-switch label="BREVETE"
                                            v-model="item.license">

                                        </v-switch>
                                    </v-col>
                                    
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <s-text label="Placa"
                                            v-model="LicensePlate"                        
                                            >
                                        </s-text>
                                    </v-col>
                                    <v-col cols="3" >
                                        <s-select-definition add :def="1115" return-object v-model="TypeBrand" label="Marca" />
                                    </v-col>
                                    <v-col cols="2">
                                        <v-switch label="TARJETA DE PROPIEDAD"
                                            v-model="PropertyCard">

                                        </v-switch>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-switch label="SOAT"
                                            v-model="Soat">

                                        </v-switch>
                                    </v-col>
                                    
                                    <v-col cols="auto">
                                        <v-btn class="mt-4" rounded fab x-small @click="addVehicle(item)">
                                            <i class="fas fa-plus"></i>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>                           
                        </v-row>
                        <v-row>
                            <v-col cols="auto">
                                <v-data-table 
                                    dense
                                    v-model="ListVehicles"
                                    :items="ListVehicles"
                                    :headers="headersListVehicles">
                                    <template v-slot:item.remove="{ item }">
                                    <v-btn
                                        @click="clickDelete(item)"
                                        x-small
                                        block
                                        rounded
                                        color="error" >
                                        <v-icon style="font-size: 16px !important"
                                            >mdi-close</v-icon >
                                    </v-btn>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:PropertyCard="{row}">
                        <v-switch disabled
                            v-model="row.PropertyCard">
                        </v-switch>
                    </template>
                    <template v-slot:Soat="{row}">
                        <v-switch disabled
                            v-model="row.Soat">
                        </v-switch>
                    </template>
                    <template v-slot:license="{row}">
                        <v-switch disabled
                            v-model="row.license">
                        </v-switch>
                    </template>
                </s-crud>
                
            </v-card-text>
            
        </v-card>
        
    </div>
</template>
<script>
    import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import _sParking from "@/services/Management/ParkingPermit.js";
    export default {
        components:{
            sToolbarPerson
        },
        data(){
            return {
                config:{
                    service: _sParking,
                    filter: {},
                    model:{
                        PropertyCard: "PropertyCard",
                        Soat: "Soat",
                        license: "license",
                        PkpID: "ID",                         
                    },
                    headers:[
                        {text:"ID", value :"PkpID"},
                        {text:"Doc. Identidad", value :"PrsDocumentNumber"},
                        {text:"Nombres", value :"NtpName"},
                        {text:"Apellido", value :"NtpPaternalSurname"},
                        // {text:"Placa", value :"LicensePlate"},
                        {text:"F. Inicio", value :"DateBeginString"},
                        {text:"F. Fin", value :"DateEndString"},
                        // {text:"Tarjeta P.", value :"PropertyCard"},
                        // {text:"Soat", value :"Soat"},
                        {text:"Brevete", value :"license"},
                    ]
                },
                item: {},
                PgdName: "",
                PgdLastName: "",
                PropertyCard: 0,
                Soat: 0,               
                ListVehicles: [],
                itemsVehicles: [],
                headersListVehicles: [
                    { text: "N°", value: "index" },
                    { text: "Placa", value: "LicensePlate" } ,  				
                    { text: "Marca", value: "TypeBrandDescription" },    
                    { text: "Tarjeta Propiedad", value: "PropertyCardDescription"},
                    { text: "Soat", value: "SoatDescription"},
                    { text: "Eliminar", value: "remove", width: 20 },       
                ],
                deleteListVehicles: [],
                TypeBrand: {},
                LicensePlate: ''
            }
        },
        methods:{
            returnPerson(item) {
                console.log(item);
                this.PgdName = item.NtpName.trim();
                this.PgdLastName = item.NtpPaternalSurname.trim() +" "+ item.NtpMaternalSurname.trim();
                this.item.PrsDocumentNumber = item.PrsDocumentNumber;
                
                //this.newPerson.PgdID = item.PgdID;

                this.item.PrsID = item.PrsID;
                this.item.NtpID = item.NtpID;
                this.item.CtrID = item.CtrID;
                this.item.WrkID = item.WkrID;                
            },
            Save(item)
            {
                
                item.PrsID = this.item.PrsID;                
                item.WrkID = this.item.WrkID;
                item.TypeBrand = this.TypeBrand.DedValue;             
                item.ListVehicle = this.ListVehicles;
                item.license = item.license ? 1 : 0;
                item.UsrCreateID = this.$fun.getUserID();
                item.save();
            },
            addVehicle(item){
                

                let vehicle = {};
                vehicle.index = this.ListVehicles.length + 1;
                vehicle.LicensePlate = this.LicensePlate;
                vehicle.TypeBrandDescription = this.TypeBrand.DedDescription;
                vehicle.TypeBrand = this.TypeBrand.DedValue;
                vehicle.PropertyCardDescription = this.PropertyCard ? 'SI' : 'NO';
                vehicle.SoatDescription = this.Soat ? 'SI' : 'NO';                
                vehicle.PropertyCard = this.PropertyCard ? 1 : 0;
                vehicle.Soat = this.Soat ? 1 : 0;
                vehicle.PkpID = item.PkpID;
                this.ListVehicles.push(vehicle);
            },
            rowSelected(item)
            {     
                if(item[0] != undefined) {      
                    this.ListVehicles = item[0].ListVehicle;
                    this.ListVehicles.forEach((vehicle, index) => {
                        vehicle.index = index + 1;
                        vehicle.PropertyCardDescription = vehicle.PropertyCard ? 'SI' : 'NO';
                        vehicle.SoatDescription = vehicle.Soat ? 'SI' : 'NO';
                    });
                }
            },  
            addRecom()
            {
                this.ListVehicles = [];
            },
            clickDelete(item){
                this.ListVehicles =
                    this.ListVehicles.filter(
                    (x) => x.PkvID != item.PkvID
                    );

                item.SecStatus = 0;
                if(item.PkvID != undefined){
                    this.deleteListVehicles.push(item);
                }
            }
        }
    }
</script>